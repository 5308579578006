import './css/App.css';
import React, { useState, useEffect } from "react";
import { Client } from 'paho-mqtt';

function MqttDashboard() {
  const [topics, setTopics] = useState([]);  // List of topics and their values
  const [inputValue, setInputValue] = useState("");  // Input for new topic
  const [mqttClient, setMqttClient] = useState(null);  // MQTT Client
  const [isConnected, setIsConnected] = useState(false);  // Connection status

  // MQTT connection details
  const mqtt_server = "broker.hivemq.com";
  const mqtt_port = 8000;

  // Load topics from local storage on component mount
  useEffect(() => {
    const savedTopics = JSON.parse(localStorage.getItem("mqttTopics"));
    if (savedTopics) {
      setTopics(savedTopics);
    }

    const client = new Client(mqtt_server, mqtt_port, `client_${Math.random().toString(16).slice(2)}`);
    setMqttClient(client);

    // Set up the client's message handler
    client.onMessageArrived = onMessageArrived;

    // Connect to the broker
    client.connect({
      onSuccess: () => {
        console.log("Connected to MQTT broker");
        setIsConnected(true);

        // Resubscribe to saved topics
        if (savedTopics) {
          savedTopics.forEach(topic => subscribeToTopic(topic.topic));
        }
      },
      onFailure: (error) => {
        console.log("Connection failed:", error);
      }
    });

    return () => {
      if (client) {
        client.disconnect();  // Cleanup on unmount
      }
    };
  }, []);

  // Subscribe to a new topic
  const subscribeToTopic = (topic) => {
    if (mqttClient && isConnected) {
      mqttClient.subscribe(topic, {
        onSuccess: () => {
          console.log(`Subscribed to topic: ${topic}`);
        },
        onFailure: (error) => {
          console.log(`Failed to subscribe:`, error);
        }
      });
    }
  };

  // Handle the arrival of messages
  const onMessageArrived = (message) => {
    const topic = message.destinationName;
    const value = message.payloadString;

    // Update the topic's value in the state and save to local storage
    setTopics((prevTopics) => {
      const updatedTopics = prevTopics.map(t => {
        if (t.topic === topic) {
          return { ...t, value: value };
        }
        return t;
      });

      localStorage.setItem("mqttTopics", JSON.stringify(updatedTopics));
      return updatedTopics;
    });
  };

  // Add a new topic and connect to it
  const addTopic = () => {
    if (inputValue.trim() !== "") {
      const newTopic = {
        topic: inputValue,
        value: "",  // Placeholder for value
      };

      const updatedTopics = [...topics, newTopic];
      setTopics(updatedTopics);  // Add the new topic to the list
      localStorage.setItem("mqttTopics", JSON.stringify(updatedTopics));  // Save to local storage

      subscribeToTopic(inputValue);  // Subscribe to the new topic
      setInputValue("");  // Clear the input
    }
  };

  // Remove a topic and unsubscribe from it
  const removeTopic = (topicToRemove) => {
    if (mqttClient && isConnected) {
      mqttClient.unsubscribe(topicToRemove, {
        onSuccess: () => {
          console.log(`Unsubscribed from topic: ${topicToRemove}`);
        },
        onFailure: (error) => {
          console.log(`Failed to unsubscribe:`, error);
        }
      });
    }

    // Filter out the topic to remove
    const updatedTopics = topics.filter(t => t.topic !== topicToRemove);
    setTopics(updatedTopics);  // Update the state to remove the topic
    localStorage.setItem("mqttTopics", JSON.stringify(updatedTopics));  // Update local storage
  };

  return (
    <div>
      {/* Input to Add Topic */}
      <div id="input">
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Zadaj text..."
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              addTopic();
            }
          }}
        />
        {/* "+" Button */}
        <button onClick={addTopic}>+</button>
      </div>

      <div id="topics-container">
        {topics.map((topicData, index) => (
          <div key={index} className="topic">
            <h3>{topicData.topic}</h3>
            <p>{topicData.value || "Príjem dát..."}</p>  {/* Display the value or a placeholder */}
            {/* "Remove" Button */}
            <button onClick={() => removeTopic(topicData.topic)}>X</button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MqttDashboard;
